.content {
    max-width: 1224px;
    display: flex;
    text-align: center;
    margin: 0 auto;
    background-color: #F5F8F9;

    .sl-bubble5 {
        margin-top: 50px;
    }
}

.mainContainer {
    background-color: #F5F8F9;
    min-height: calc(100vh - 60px);
}

.containerTab {
    padding-top: 10px !important;
    margin: 0 auto;
    max-width: 1224px;
    background-color: rgb(245, 248, 249);
}

.css-ahj2mt-MuiTypography-root {
    font-size: 12px !important;
}

@media screen and (max-width: 1200px) {
    .autocomplete {
        min-width: 250px !important;
    }
}

.css-tuqgnz-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: rgb(51, 51, 51) !important;
}


.css-13cwhhk.Mui-selected {
    color: rgb(51, 51, 51) !important;
}


@media screen and (max-width: 570px) {
    .listaTickets {
        max-height: 250px !important;
    }
}