.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.font-link {
  font-family: 'Poppins';
}

h1,
h2,
h3,
h4,
h5,
p,
h6,
a,
button,
span,
ul,
ol,
body,
header, label, input {
  font-family: 'Poppins' !important;
}

h5{
  font-size: 14px;
}

.errorText {
  text-align: left;
  color: #ff4848;
  font-size: 12px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

button:hover {
  cursor: pointer !important
}

.blue_button {
  padding: 10px;
  margin-right: 10px;
  background-color: #2a8daa;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px !important;
  color: #fff;
  border-radius: 3px;
  border: none;
}

.blue_button:hover,
.yellow_button:hover,
.red_button:hover,
.scan_button:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px !important;
}

.yellow_button {
  padding: 10px;
  background-color: #f9ce55;
  color: #0181AA;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px !important;
  border-radius: 3px;
  border: none;
}

.red_button {
  padding: 10px;
  background-color: #ff4848;
  color: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px !important;
  border-radius: 3px;
  border: none;
}

.scan_button {
  width: 100%;
  padding: 15px !important;
  background-color: #f9ce55 !important;
  box-shadow: rgba(155, 155, 155, 0.4) 0px 4px 12px !important;
  color: #0181AA !important;
  border-radius: 3px !important;
  border: none;
}