.detail-content {
    
    padding: 40px 20px 0 20px;

    .no-select {
        padding-top:50px;
        margin: 0 auto;
        text-align: center;
        vertical-align:middle;
    }
    p {
        font-size: 20px;
    }

    #detalle_cab {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        vertical-align: middle;

        span {
            padding: 10px;
        }

    } 
    
    table {
        padding: 20px;
        text-align: left;
        width: 100%;
    }

    *:focus {
        outline: none !important;
    }

    .cuadroPresupueso {
        margin: 0 auto;
        border: 1px solid #688A91;
        border-radius: 10px;
        min-height: 200px;
        max-width: 1024px;
        //display: inline-flex;
        align-items: start;
        justify-content: start;
        
        .Mui-focused .MuiOutlinedInput-notchedOutline{
            border-color: #2a8daa !important;
        }
    }
}

#logo {
    text-align: center;
    width:150px;
}