body{
    width: 100%;
    background-color: #ffffff;
    margin:0;
    padding:0;
    -webkit-font-smoothing: antialiased;
    
    //mso-margin-top-alt:0px; mso-margin-bottom-alt:0px; mso-padding-alt: 0px 0px 0px 0px;
}

p,h1,h2,h3,h4{
    margin-top:0;
    margin-bottom:0;
    padding-top:0;
    padding-bottom:0;
}

#todo {
    max-width:540px;
    margin:20px;
}

html{
    width: 100%;
}

.bord-1{
    border-right:2px #eeeeee solid;
    border-bottom: 2px #eeeeee solid;
}
.bord-2{
    border-right:1px #eeeeee solid;
    border-bottom: 1px #eeeeee solid;
}

#productos td{
    padding-bottom: 5px;
}
.total{
    font-size: 14px;
}
