ul {
    list-style-type: none;
    text-align: center;
    padding: 0;
    height: calc(100vh - 180px);

    li {
        padding: 10px;
        border: solid #c9c9c9 ;
        border-width: 1px 1px 1px 1px;
        margin-top: 2px;
        border-radius: 5px;
        margin-bottom: 7px;
    }
}

.titleSection {
    margin: 0; 
    color: #4d4d4d;
}

*{
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}