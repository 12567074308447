.menu-header {

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;

    div {
        width: 150px;
        
        a {
            text-decoration: none;
            color: #fff;
        }

        span {
            align-items: center;
        }
    }

    #logo {
        width:150px;
        margin-left: 20px;
        margin: 0 auto;
    }
}


.App-header {
    background-color: #2a8daa;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(4px + 2vmin);
    color: white;
  }