.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 50% !important;
    height: 50% !important;
}

.modalqr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}